<script setup>
import TeaserActu from '~/components/actualites/TeaserActu.vue';
import {useApi} from "~/src/api.js";
import {useDevelStore} from "~/stores/devel.js";
const api = useApi();
const develStore = useDevelStore();

const {data, pending, error, refresh} = useAsyncData('news-list', async () => {
  return await api.actualites.find({pagination: {limit: 4}, sort: ['date:desc', 'title:asc']});
});
</script>
<template>
<div>
  <div v-if="!develStore.enableBlog" class="text-center text-2xl">
    Les actualités sont indisponibles pour le moment
  </div>
  <div v-else-if="develStore.enableBlog">
    <div v-if="data && data.length > 0 && !error && !pending" class="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 md:max-w-none lg:grid-cols-2 lg:max-w-none">
      <TeaserActu v-for="post in data" :post="post" :key="`dernieres-actus-${post.id}`" />
    </div>
    <div class="text-center text-2xl" v-else-if="!pending && (!data || data.length === 0)">
      Désolé, il n'y a aucune actualité publiée pour le moment
    </div>
    <div class="text-center text-2xl" v-if="pending">
      Chargement en cours
    </div>
    <div class="text-center text-2xl" v-if="error">
      {{error}}
    </div>

  </div>
</div>

</template>
