<template>
  <div>
    <img class="w-full object-cover" :src="image.getSrcUrl(format)" :alt="image.alternativeText" />
  </div>
</template>
<script setup>
import {toRef} from "vue";
import {Image} from "~/src/models/Image.js";

const props = defineProps({
  image: {type: Object, required: true},
  format: {type: String, default: () => 'large'}
})
const imageProp = toRef(props, 'image');
const image = ref(new Image(imageProp.value))
</script>
