<template>
  <div class="editorjs-vue-renderer">
    <div :data-version="version" :data-content-version="data.version" :data-time="data.time" :data-rendered-at="renderedDate">
      <pre v-if="renderAsString">
        {{dataAsString}}
      </pre>
      <div v-else class="prose lg:prose-xl mx-auto dark:prose-invert">
        <BlockRenderer v-for="block in data.blocks" :block="block" :key="block.id" />
      </div>
    </div>
  </div>
</template>
<script setup>
import {toRef, ref, computed, watch, onMounted} from "vue";
import BlockRenderer from "~/components/editorjs/BlockRenderer.vue";
import dayjs from "~/src/dayjs";

const version = '2.23.2';
const props = defineProps({
  dataAsString: {type: String, required: false},
  data: {type: Object, required: false},
  renderAsString: {type: Boolean, required: false, default: () => false},
})

const renderAsString = toRef(props, 'renderAsString');
const _dataAsString = toRef(props, 'dataAsString');
const _data = toRef(props, 'data');

if (_dataAsString.value === null && _data.value === null) {
  throw 'At leat one of the props dataAsString or data is required'
}

const data = ref({});
const dataAsString = computed(() => JSON.stringify(data.value));

if (_data.value) {
  data.value = _data.value;
}
else if (_dataAsString.value) {
  try {
    data.value = JSON.parse(_dataAsString.value);
  }
  catch (e) {
    console.error('JSON parse error');
  }
}
const renderedDate = ref(dayjs().format());

</script>
