<template>
  <pre v-if="renderAsString">
    {{dataAsString}}
  </pre>
  <component v-else-if="block.type === 'header'" :is="'h'+block.data.level" :class="alignClasses">
    {{ block.data.text }}
  </component>
  <p v-else-if="block.type === 'paragraph'" v-html="block.data.text" :class="alignClasses"></p>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{ShopOpeningHours}}'">
    <ShopOpeningHours />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{PhoneLink}}'">
    <PhoneLink />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{EmailLink}}'">
    <EmailLink />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{DernieresActus}}'">
    <DernieresActus />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{ItineraryButton}}'">
    <ItineraryButton />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{EpicerieContent}}'">
    <EpicerieContent />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{MentionsContent}}'">
    <MentionsContent />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{LeVracContent}}'">
    <LeVracContent />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{MaintenanceContent}}'">
    <MaintenanceContent />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{NewsletterSengridForm}}'">
    <NewsletterSengridForm />
  </div>
  <div v-else-if="block.type === 'raw' && block.data.html === '{{Logo}}'">
    <Logo />
  </div>
  <div v-else-if="block.type === 'raw'" v-html="block.data.html"></div>
  <pre v-else-if="block.type === 'code'">{{ block.data.code }}</pre>
  <component v-else-if="block.type === 'list'" :is="block.data.style === 'ordered' ? 'ol' : 'ul'">
    <li v-for="item in block.data.items" v-html="item"></li>
  </component>
  <Callout v-else-if="block.type === 'warning'" :type="'warning'" class="not-prose my-5" :title="block.data.title">{{block.data.message}}</Callout>
  <div v-else-if="block.type === 'checklist'" class="my-5 px-2">
    <div v-for="(item, i) in block.data.items" :key="block.id+'-i'">
      <input type="checkbox" readonly :checked="item.checked" style="pointer-events: none;"/>
      <span class="pl-2">{{item.text}}</span>
    </div>
  </div>
  <div v-else-if="block.type === 'quote'" class="my-5 px-2">
    <blockquote class="text-left" :class="{['text'+block.data.alignment]: true}">
      <p>{{ block.data.text }}</p>
      <cite>{{ block.data.caption }}</cite>
    </blockquote>
  </div>
  <div v-else-if="block.type === 'image'" class="my-5 px-2">
    <StrapiImage :image="block.data.file" />
  </div>
  <details v-else class="bg-orange-800 my-5">
    <summary class="text-orange-200 p-2" >Unsupported Block type : {{block.type}}</summary>
    <pre class="bg-orange-800" v-html="JSON.stringify(block, null, '  ')"></pre>
  </details>
</template>
<script setup>
import {toRef, ref, computed} from "vue";
import Callout from "~/components/Callout.vue";
import DernieresActus from "~/components/actualites/DernieresActus.vue";
import ShopOpeningHours from "~/components/content/ShopOpeningHours.vue";
import EpicerieContent from "~/components/content/EpicerieContent.vue";
import MaintenanceContent from "~/components/content/MaintenanceContent.vue";
import LeVracContent from "~/components/content/LeVracContent.vue";
import MentionsContent from "~/components/content/MentionsContent.vue";
import EmailLink from "~/components/content/EmailLink.vue";
import PhoneLink from "~/components/content/PhoneLink.vue";
import Logo from "~/components/content/Logo.vue";
import StrapiImage from "~/components/strapi/StrapiImage.vue";
import ItineraryButton from "~/components/content/ItineraryButton.vue";
import NewsletterSengridForm from "~/components/forms/NewsletterSengridForm.vue";

const supportedBlockTypes = ['header', 'paragraph', 'raw', 'code', 'list', 'warning', 'checklist', 'quote', 'image'];

const props = defineProps({
  block: {type: Object, required: true},
  renderAsString: {type: Boolean, required: false, default: () => false},
})

const renderAsString = toRef(props, 'renderAsString');
const block = toRef(props, 'block');

if (supportedBlockTypes.indexOf(block.value.type) === -1) {
  console.warn('Unsupported EditorJS block type :', {...block.value})
}

const textAlignment = computed(() => {
  try {
    return block.value.tunes.tuneAlignment.alignment;
  }
  catch (e) {
    return 'left';
  }
})
const alignClasses = computed(() => {
  return {
    'text-right': textAlignment.value === 'right' || false,
    'text-center': textAlignment.value === 'center' || false,
  }
})

</script>
